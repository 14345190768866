import { useEffect } from "react";
import { Router } from "../i18n";

/**
 * Redirects root path / or /en to /en/design
 */
const IndexLanding = (): JSX.Element => {
  useEffect(() => {
    // const { search } = useLocation();
    if (Router.pathname === "/") {
      Router.push(`/design${window.location.search}`);
    }
  });

  return <></>;
};

export default IndexLanding;
